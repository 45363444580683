import TopWinnerService from '@/libs/top-winner.service'

export default {
	namespaced: true,
	state: {},
	getters: {},
	mutations: {},
	actions: {
		fetchTopWinners(ctx, queryParams) {
			return new Promise((resolve, reject) => {
				TopWinnerService.getTopWinnerLists(queryParams)
					.then(response => resolve(response))
					.catch(error => reject(error))
			})
		},
    }
}
