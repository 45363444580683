/* eslint-disable vue/html-self-closing */
<template>
  <div>
    <top-winners-list-filters
      @refetch-data="refetchData"
      :codeProduct.sync="codeProduct"
      :username-filter.sync="usernameFilter"
      :user-id-filter.sync="userIdFilter"
      :countUserFilter.sync="countUserFilter"
      :amount-filter.sync="amountFilter"
      :fromdate-filter.sync="fromdateFilter"
      :todate-filter.sync="todateFilter"
      :handle-search.sync="fetchTopWinners"
    />
    <b-card no-body class="mb-0">
      <b-table
          ref="refTopWinnerListTable"
          class="position-relative table-white-space"
          :items="topWinnerList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
          empty-text="No matching records found"
          sort-by="win_loss"
          :sort-desc=true
      >
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTopWinners"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText, numberFormat, formatDateTime } from "@core/utils/filter";
import TopWinnersListFilters from "./TopWinnersListFilters.vue";
import useTopWinnersList from "./useTopWinnersList";
import topWinnerStoreModule from "./topWinnerStoreModule";

export default {
  components: {
    TopWinnersListFilters,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  setup() {
    const CAMPAIGN_RISK_MANAGEMENT_TOP_WINNERS_MODULE_NAME =
      "campaign-risk-management-top-winners";

    // Register module
    if (!store.hasModule(CAMPAIGN_RISK_MANAGEMENT_TOP_WINNERS_MODULE_NAME))
      store.registerModule(
        CAMPAIGN_RISK_MANAGEMENT_TOP_WINNERS_MODULE_NAME,
        topWinnerStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CAMPAIGN_RISK_MANAGEMENT_TOP_WINNERS_MODULE_NAME))
        store.unregisterModule(
          CAMPAIGN_RISK_MANAGEMENT_TOP_WINNERS_MODULE_NAME
        );
    });

    const {
      fetchTopWinners,
      topWinnerList,
      tableColumns,
      perPage,
      currentPage,
      totalTopWinners,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTopWinnerListTable,
      refetchData,

      // UI
      // Extra Filters
      usernameFilter,
      userIdFilter,
      amountFilter,
      countUserFilter,
      fromdateFilter,
      todateFilter,
      codeProduct,
    } = useTopWinnersList();

    return {
      fetchTopWinners,
      topWinnerList,
      tableColumns,
      perPage,
      currentPage,
      totalTopWinners,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTopWinnerListTable,
      refetchData,

      // Filter
      avatarText,
      numberFormat,
      formatDateTime,

      // Extra Filters
      usernameFilter,
      userIdFilter,
      amountFilter,
      countUserFilter,
      fromdateFilter,
      todateFilter,
      codeProduct,
    };
  },
  mounted() {
    this.fetchTopWinners();
  },
};
</script>
