import {computed, ref, watch} from "@vue/composition-api";
import store from "@/store";
import {numberFormat} from "@core/utils/filter";
// Notification
import {useToast} from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useTopWinnersList() {
  // Use toast
  const toast = useToast();

  const refTopWinnerListTable = ref(null);
  const topWinnerList = ref([]);
  const codeProduct = [
    {
      label: "Win",
      value: 1,
    },
    {
      label: "Loss",
      value: 2,
    },
  ];

  // Table Handlers
  const tableColumns = [
    "index",
    { key: "username", sortable: false },
    { key: "deposits_sum_amount", label: "Sum Deposit", sortable: true },
    { key: "sum_amount_bonus", label: "Sum Bonus", sortable: true },
    { key: "withdrawals_sum_amount", label: "Sum Withdrawal", sortable: true },
    { key: "sum_amount_win", label: "Sum Win", sortable: true },
    { key: "win_loss",
      label: "Win Loss",
      sortable: true,
      formatter: (value, key, item) => {
        return numberFormat(item.win_loss/1000)
      }
    },
    { key: "adjust_balances_sum_amount_credit", label: "Sum Credit", sortable: true },
    { key: "adjust_balances_sum_amount_debit", label: "Sum Debit", sortable: true },
    // { key: "transaction", label: "quantity transactions" },
  ];
  const perPage = ref(20);
  const totalTopWinners = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const usernameFilter = ref(null);
  const userIdFilter = ref(null);
  const amountFilter = ref(null);
  const countUserFilter = ref(null);
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 7);
  const fromdateFilter = ref(chuyenDoiNgayThangNam(currentDate.toISOString()));
  const todateFilter = ref(chuyenDoiNgayThangNam(new Date().toISOString()));

  function chuyenDoiNgayThangNam(isoString) {
    const ngay = new Date(isoString);
    const nam = ngay.getFullYear();
    const thang = (ngay.getMonth() + 1).toString().padStart(2, "0"); // Tháng bắt đầu từ 0, cần cộng thêm 1
    const ngayTrongThang = ngay.getDate().toString().padStart(2, "0");

    return `${nam}-${thang}-${ngayTrongThang}`;
  }

  const dataMeta = computed(() => {
    const localItemsCount = refTopWinnerListTable.value
      ? refTopWinnerListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTopWinners.value,
    };
  });

  const refetchData = () => {
    refTopWinnerListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      //   perPage,
      searchQuery,
      usernameFilter,
      userIdFilter,
      amountFilter,
      countUserFilter,
      fromdateFilter,
      todateFilter,
    ],
    () => {
      refetchData();
    }
  );

  const fetchTopWinners = (ctx, callback) => {
    store
      .dispatch("campaign-risk-management-top-winners/fetchTopWinners", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        // sortBy: sortBy.value,
        // sortDesc: isSortDirDesc.value,
        // countUser: usernameFilter.value,
        amount: amountFilter.value,
        countUser: countUserFilter.value,
        userId: userIdFilter.value,
        fromdate: fromdateFilter.value,
        todate: todateFilter.value,
      })
      .then((response) => {
        topWinnerList.value = mappingDatasource(response.topWinners);
        const { topWinners, total, pageSize } = response;
        // callback(topWinners);
        totalTopWinners.value = total;
        perPage.value = pageSize;
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching top winners list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const mappingDatasource = (dataList) => {
    return dataList.map((item, index) => {
      return {
        index: index + 1,
        username: item.user ? item.user.username : "",
        deposits_sum_amount: item.user.deposits_sum_amount
            ? numberFormat(item.user.deposits_sum_amount / 1000)
            : "",
        sum_amount_bonus: item.user.sum_amount_bonus
            ? numberFormat(item.user.sum_amount_bonus / 1000)
            : "",
        withdrawals_sum_amount: item.user.withdrawals_sum_amount
            ? numberFormat(item.user.withdrawals_sum_amount / 1000)
            : "",
        sum_amount_win: item.sum_amount_win
            ? numberFormat(item.sum_amount_win / 1000)
            : "",
        adjust_balances_sum_amount_debit: item.user.adjust_balances_sum_amount_debit
            ? numberFormat(
                item.user.adjust_balances_sum_amount_debit / 1000
            )
            : "",
        adjust_balances_sum_amount_credit: item.user.adjust_balances_sum_amount_credit
            ? numberFormat(
                item.user.adjust_balances_sum_amount_credit / 1000
            )
            : "",
        win_loss: addNotNull(
            item.user.deposits_sum_amount,
            item.user.sum_amount_bonus,
            item.user.withdrawals_sum_amount)

      }
    })
  }

  const addNotNull = (params1, params2, params3) => {
    params1 = params1 !== null ? params1 : 0;
    params2 = params2 !== null ? params2 : 0;
    params3 = params3 !== null ? params3 : 0;

    return parseInt(params3) - parseInt(params1) - parseInt(params2);
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchTopWinners,
    topWinnerList,
    tableColumns,
    perPage,
    currentPage,
    totalTopWinners,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTopWinnerListTable,

    refetchData,

    // Extra Filters
    usernameFilter,
    userIdFilter,
    amountFilter,
    countUserFilter,
    fromdateFilter,
    todateFilter,
    codeProduct,
  };
}
